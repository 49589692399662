<template>
    <div class="">
        <div v-if="resourcesList.length >= 1 && !loading" class="">
            <h3 class="card-title d-flex">
                Manage Customers
            </h3>
            <div class="mt-4">
                <ItemList :allowAddButton="true" :title="title" :headers="headers" :inputData="resourcesList" caller="customers" @on-item-click="handleSelected" @on-new-click="activePrompt = true" @load-more="onLoadMore"/>
            </div>
        </div>
        <div v-if="resourcesList.length === 0" id="fallback" class="vs-con-loading__container">
            <div v-if="!loading">
                <vs-card>
                    <div class="login-tabs-container">
                        <vs-row>
                            <vs-col class="full-page-bg-color p-0 login-tabs-container" vs-type="flex" vs-xs="0" vs-sm="6" vs-lg="6">
                                <img src="@/assets/images/pages/login.png" alt="Payment Link" class="m-auto w-100 h-100">
                            </vs-col>
                            <vs-col class="login-tabs-container" vs-xs="12" vs-sm="6" vs-lg="6">
                                <div class="p-4 h-100 pb-4">
                                    <div class="mt-4">
                                        <span class="stripe"></span>
                                        <h6 class="d-inline text-muted">Utupay</h6>
                                    </div>
                                    <h2 class="mt-4 text-primary">Manage Customers</h2>
                                    <p class="mt-2 lead font-weight-bold text-muted">
                                        Create and share a Utupay Payment Link to customers via email, SMS, whatsapp, messenger, and on any social media platform. Customers can complete payments to your account with your link through the Utupay mobile application or through a web browser.
                                    </p>
                                    <vs-button class="get-started"  @click="activePrompt = true" color="primary">Create New Customer</vs-button>
                                </div>
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-card>
            </div>
        </div>
        
        <AppDialog :showPrompt.sync="activePrompt" @on-success="handleSuccess" title="Create New Customer" component="createCustomer"/>
    </div>
</template>

<script>
import ItemList from '../../custom/ItemList';
import { mapActions, mapGetters } from 'vuex';
import AppDialog from '../../custom/AppDialog';

export default {
    name: 'Customers',
    components: { ItemList, AppDialog },
    mounted() {
        if (this.resourcesList.length === 0) this.showLoading();
        let payload = {
            close: () => {
                this.$vs.loading.close('#fallback > .con-vs-loading');
                this.loading = false;
                window.console.log("Data", this.resourcesList);
            }
        };
        this.bindCustomers(payload);
    },
    computed: {
        //...mapState('resources', ['resourcesList']),
        ...mapGetters('customer', {
            resource: 'getCustomers',
        })
    },
    data() {
        return {
            loading: true,
            activePrompt: false,
            title: '',
            headers: [
                {
                    key: 'name',
                    value: 'Customer Name'
                }, {
                    key: 'email',
                    value: 'Customer Email'
                }, {
                    key: 'phone_number',
                    value: 'Phone Number'
                }, {
                    key: 'creation_date',
                    value: 'Creation Date'
                }
            ],
            resourcesList: []
        }
    },
    methods: {
        handleSuccess() {
            this.activePrompt=false;
        },
        handleSelected(sectorHead) {
            this.$router.push({
                name: 'SectorHeadDetails',
                params: {data: sectorHead}
            });
        },
        showLoading() {
            this.loading = true;
            this.$vs.loading({
                text: 'Loading sector heads...',
                //type: 'point',
                container: '#fallback'
            });
        },
        stopLoading() {
            this.loading = false;
            this.$vs.loading.close('#fallback > .con-vs-loading');
        },
        onLoadMore(limit) {
            let payload = {
                collection: 'users', 
                account: 'sector_head',
                limit: limit,
                lastSnapshot: this.resourcesList[this.resourcesList.length -1]
            };
            this.bindMoreCustomers(payload);
        },
        ...mapActions('customer', ['bindCustomers', 'bindMoreCustomers'])
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        resource(newValue, oldValue) {
            this.resourcesList = [...new Set([...this.resourcesList, ...newValue])];
            window.console.log("NEWV:", newValue, "OLDV:", oldValue, "RESOURCE:", this.resourcesList);
        }
    }
}
</script>

<style lang="scss" scoped>
    .login-tabs-container {
        min-height: 505px;

        .con-tab {
            padding-bottom: 14px;
        }

        .con-slot-tabs {
            margin-top: 1rem;
        }
    }

    .stripe {
        height: 2px;
        width: 45px;
        background-color: #32efa2;
        display: inline-block;
        margin-right: 5px;
    }

    .get-started {
        bottom: 140px;
        right: 50px;
        position: absolute;
    }
</style>